<template>
  <div>
    <div class="header" style="padding:.2rem">
      <van-row gutter="10">
        <van-col @click="onClickBack">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
            style="height: 25px"
          />
          <span style="font-size: 25px; color: white;font-weight:bold">借调旅行社</span>
        </van-col>
      </van-row>
    </div>
    <div style="padding:.2rem">
      <card class="container">
        <template #content>
        <van-pull-refresh v-model="loading" @refresh="refreshList" style="padding-bottom:2rem">
          <van-list v-model="listLoading" :finished="listFinished" finished-text="没有更多了" @load="refreshList" 
            v-if="tmpCompanies.length > 0">
            <van-row v-for="(item, index) in tmpCompanies" :key="index"
              class="row" @click="toCompanyInfo(item.travel_company.id)">
              <van-row style="margin-top:.2rem">
                <van-col span="5">
                  <van-image :src="item.travel_company.avatar" fit="cover" round width="60" height="60"/>
                </van-col>
                <van-col span="19">
                  <van-row type="flex" style="line-height:.3rem">
                    <span style="font-weight:700;font-size:16px;color:black">{{item.travel_company.name}}</span>
                    <van-rate v-model="item.travel_company.rate_star" size="10" color="#FFAA00" 
                      allow-half void-color="#fff"
                      style="margin-top:.05rem;margin-left: .1rem"/>
                  </van-row>
                  <van-row style="line-height:10px">
                    <span>法人：{{item.travel_company.legal_person}}</span>
                  </van-row>
                  <van-row style="line-height:10px">
                    <span>电话：{{item.travel_company.telephone === ''? "暂无": item.travel_company.telephone}}</span>
                  </van-row>
                </van-col>
              </van-row>
            </van-row>
          </van-list>
          </van-pull-refresh>
          <van-row v-if="tmpCompanies.length === 0" class="row" style="text-align:center">
            <van-empty description="当前没有借调旅行社"/>
          </van-row>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
import card from "@/views/components/Card"
export default {
  components: {
    card
  },
  data() {
    return {
      tmpCompanies: [],
      loading: false,
      listLoading: false,
      paging: {
        "total_count": 0,
        "total_pages": 0,
        "current_page": 0,
        "next_page": null,
      }
    }
  },
  computed: {
    listFinished() {
      return this.paging.next_page == null
    }
  },
  created() {
    this.refreshList()
  },
  methods: {
    onClickBack() {
      this.$router.go(-1);
    },
    toCompanyInfo(id) {
      this.$router.push(`/wode/company/${id}`)
    },
    refreshList() {
      const _self = this
      if(this.paging.current_page === 0) {
        this.paging.next_page = 1
      }
      this.$store.dispatch('wode/getTmpCompanies', "0,1", this.paging.next_page).then((res) => {
        if(res.status === 200) {
          _self.tmpCompanies = res.data
          _self.listLoading = false
          _self.loading = false
          _self.paging.current_page = res.current_page
          _self.paging.next_page = res.next_page
          _self.paging.total_count = res.total_count
          _self.paging.total_pages = res.total_pages
        }
      })
    }
  }
}
</script>

<style scoped>
.container {
  height:60vh;
  overflow-y:scroll;
  margin-top:.3rem;
  padding: .2rem .2rem 2rem .2rem;
  overflow-y: scrol;
}
.row_title span{
  font-size: .3rem;
  font-weight: 600;
}
.row span{
  font-size: 12px;
  color: #666;
}
</style>